import * as $ from 'jquery';
import { BrowserModule } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { JwtModule } from '@auth0/angular-jwt';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Routes, RouterModule } from '@angular/router';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { AuthGuard } from './shared/guard/auth.guard';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './shared/guard/auth.service';
import { DateService } from './shared/services/date/date.service';
import { LoginService } from './login/login.service';
import { ApiService } from './shared/services/api/api.service';
import { RestCountriesApiService } from './shared/services/rest-countries-api/rest-countries-api.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: getToken,
        whitelistedDomains: ['localhost:4200']
      }
    })
  ],
  providers: [
    AuthGuard,
    AuthService,
    DateService,
    JwtHelperService,
    LoginService,
    DatePipe,
    ApiService,
    RestCountriesApiService
  ],
  bootstrap: [
    AppComponent
  ]
})

export class AppModule { }
export function getToken() {
  return localStorage.getItem('currentUser');
}
