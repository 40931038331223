import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

@Injectable()
export class DateService {

  constructor(
    private datePipe: DatePipe
  ) { }

  convertToJsObject(dateTimeFormat) {
    let date = new Date(dateTimeFormat);
    // getMonth() starts from 0, instead of 1 https://www.w3schools.com/jsref/jsref_getmonth.asp
    dateTimeFormat = {
      "year": date.getFullYear(),
      "month": date.getMonth() + 1,
      "day": date.getDate()
    }
    return dateTimeFormat;
  }

  convertToDateTime(jsObject) {
    // month array in date() starts from 0, therefore a shift is needed for calculation https://www.w3schools.com/jsref/jsref_getmonth.asp
    let date = new Date(
      jsObject['year'],
      jsObject['month'] - 1,
      jsObject['day'],
      0, 0, 0, 0
    );
    let dateFormated = this.datePipe.transform(date, 'yyyy-MM-ddThh:mm:ssZ')
    return dateFormated;
  }

}
