import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';
import { LoginService } from 'app/login/login.service';

@Injectable()
export class RestCountriesApiService {

  constructor(
    private http: HttpClient,
    private loginService: LoginService
  ) { }

  // Query an open Source Project to get Country Name & IsoCode
  getCountries(): Observable<any> {
    const headers = new HttpHeaders();
    const params = new HttpParams();

    return this.http.get('https://nickis-images.s3.eu-central-1.amazonaws.com/countries/countriesV2.json', { responseType: 'json', headers, params });
  }

}
