import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';
import { LoginService } from '../../../login/login.service';
import { environment } from '../../../../environments/environment';

@Injectable()
export class ApiService {

  constructor(
    private http: HttpClient,
    private loginService: LoginService
  ) { }

  getHttp(endpoint): Observable<any> {

    // add authorization header with jwt token
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.loginService.token);
    const params = new HttpParams();

    return this.http.get(environment.apiUrl + endpoint, { responseType: 'json', headers, params });
  }

  getHttpFile(endpoint): Observable<any> {

    // add authorization header with jwt token
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.loginService.token);
    const params = new HttpParams();

    return this.http.get(environment.apiUrl + endpoint, { responseType: 'blob', headers, params });
  }

  postHttp(endpoint, body): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.loginService.token);
    const params = new HttpParams();

    return this.http.post(environment.apiUrl + endpoint, body, { responseType: 'json', headers, params });
  }

  postHttpWithFile(endpoint, file: File, body: object = {}): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.loginService.token);
    const params = new HttpParams();

    const formData = new FormData();
    for (const property in body) {
      if (body.hasOwnProperty(property)) {
          formData.append(property, Array.isArray(body[property]) ? JSON.stringify(body[property]) : body[property]);
      }
    }
    if (undefined !== file) {
        formData.append('file', file, file.name);
    }

    return this.http.post(environment.apiUrl + endpoint, formData, { responseType: 'json', headers, params });
  }

}
