import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class AuthService {
  constructor(
    public jwtHelper: JwtHelperService
  ) { }

  public isAuthenticated(): boolean {
    const currentUser = localStorage.getItem('currentUser')
    if (currentUser !== null) {
      // Check whether the token is expired and return and true or false
      return !this.jwtHelper.isTokenExpired(currentUser);
    }
    return false;
  }
}
